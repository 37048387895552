import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"


const Social = () => {
    return (
      <Container>
        <Row className="my-3 px-3 justify-content-around">
          <a href="https://instagram.com/yourtherapydoncaster" target="blank" rel="noopen noreferrer">
            <img
              src={instagram}
              width="40"
              height="40"
              className="d-inline-block align-top footer-social"
              alt="Link to YourTherapy on Instagram"
            />
          </a>
          <a href="https://facebook.com/yourtherapydoncaster" target="blank" rel="noopen noreferrer">
            <img
              src={facebook}
              width="40"
              height="40"
              className="d-inline-block align-top footer-social"
              alt="Link to YourTherapy on Facebook"
            />
          </a>
        </Row>
      </Container>
    )
  }

  export default Social
