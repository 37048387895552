import React from "react"
import { Helmet } from 'react-helmet';

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/bootstrap.min.css"
import "normalize.css"
import "./layout.css"
import Header from "./header"
import Footer from "./footer"

const Layout = props => {
  return (
    <div>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#c9cf8c" />
        <meta name="theme-color" content="#fcfcfc" />
      </Helmet>
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
