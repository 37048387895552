import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "gatsby"
import Social from "./social"
import logo from "../images/logo.png"
import bacp from "../images/bacp.png"

import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"


const Footer = () => {
  return (
    <div className="bg-secondary">
      <Container fluid="xl">
        <Row className="py-5">
          <Col xs={12} md={{span:3, offset:1}} xl={{span:2,offset:0}} className="d-flex flex-column align-items-center justify-content-center justify-content-md-start">
            <Link to="/">
              <img
                src={logo}
                width="180"
                height="90"
                className="align-top mx-auto my-2"
                alt="YourTherapy Doncaster counselling services logo"
              />
            </Link>
            <Social />
          </Col>
          <Col xs={12} sm={{span:8, offset:2}} md={{span:4, offset:0}} xl={{span:4,offset:2}}>
            <Nav className="my-3 mt-md-0 d-flex flex-column text-center">
              <Nav.Item className="mb-2">
                <Link to="/should-I-get-counselling/">Should I get counselling?</Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to="/about#what-is-integrative-therapy">What is integrative therapy?</Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to="/about#meet-alex">Meet your therapist</Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to="/about#find-us">Directions</Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to="/about#prices">Price</Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col xs={12} md={{span:3, offset:0}} xl={{span:3,offset:1}} className="d-flex flex-column align-items-center">
            <Image src={bacp} fluid alt="BACP Registered Member No. 385738 - MBACP" className="mx-auto my-2 my-lg-0" style={{width:'100%',maxWidth:'240px'}} />
            <Button href="/resources/" variant="outline-dark" style={{fontSize:'80%',lineHeight:1}} className="mt-4 w-100 border-danger">
              If you are in danger or having a mental health emergency click here for organisations that can provide help immediately
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Footer
