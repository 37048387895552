import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "gatsby"

import logo from "../images/logo.png"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Button from "react-bootstrap/Button"

const Header = () => {
  return (
    <Container fluid="xl">
      <Navbar expand="md" bg="transparent" className="text-uppercase">
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              width="150"
              height="75"
              className="d-inline-block align-top"
              alt="YourTherapy Doncaster counselling services logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="header-navbar" label="Toggle navigation" />
        <Navbar.Collapse id="header-navbar">
          <Nav justify className="m-auto justify-content-around text-center small font-weight-bolder">
          <Nav.Item className="my-3 mx-2">
            <Link to="/">Home</Link>
          </Nav.Item>
            <Nav.Item className="my-3 mx-2">
              <Link to="/should-I-get-counselling/">Should I get counselling?</Link>
            </Nav.Item>
            <Nav.Item className="my-3 mx-2">
              <Link to="/about/">About YourTherapy</Link>
            </Nav.Item>
            <Button href="/contact/" className="d-md-none my-2 text-uppercase font-weight-bolder" variant="outline-dark">
              Book
            </Button>
          </Nav>
        </Navbar.Collapse>
        <Button href="/contact/" className="ml-auto d-none d-md-block py-2 px-4 mr-md-3 text-uppercase font-weight-bolder" variant="outline-dark">
          Book
        </Button>
      </Navbar>
    </Container>
  )
}
export default Header
